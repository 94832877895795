import React from 'react'
import GalleryWithLightbox from '../../../components/gallery-with-lightbox'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import image1 from '../../../assets/images/albums/installations/berlin-project/1-Tensiones - Undisclose Site Project - Berlin - ceramica e hilo - 76.2ancho x 178 alto cm.jpg'
import image2 from '../../../assets/images/albums/installations/berlin-project/2-Tensiones - close up.jpg'
import image3 from '../../../assets/images/albums/installations/berlin-project/3-Buscando Luz.jpg'
import image4 from '../../../assets/images/albums/installations/berlin-project/4-Cosas que Quedaron sin Decir.jpg'
import image5 from '../../../assets/images/albums/installations/berlin-project/5-Sin Salida.jpg'
import image6 from '../../../assets/images/albums/installations/berlin-project/6-Cerca de la Puerta.jpg'
import image7 from '../../../assets/images/albums/installations/berlin-project/7-Cerca de La Puerta los Dos.jpg'
import image8 from '../../../assets/images/albums/installations/berlin-project/8- Cerca de La Puerta Los Dos -close up-.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <p>
        "Undisclosed sites"<br />
        Ceramic and mixed media installation - 2018/2019
      </p>
      <p>
        My focus on installing this project in undisclosed sites in Berlin, was a personal experimentation between the emotions and past history of the city. Mostly in empty spaces, worn out by time, where tension, solitude and sadness were still present. These interventions with my mixed media works were not accessible to the public.
      </p>
      <p>
        Materials: unglazed handmade ceramic chains and ceramic sticks, stitched fabric chains, threads, natural light and fluorescent light.
      </p>
      <GalleryWithLightbox
        photos={PHOTO_SET}
        direction='column'
        margin={8}
      />
    </main>
    <Footer />
  </Layout>
)

const PHOTO_SET = [
  {
    src: image1,
    width: 568,
    height: 960,
    caption: 'Tensiones - Undisclosed Site Project - Berlin - ceramic and string - 76.2x178cm'
  },
  {
    src: image2,
    width: 960,
    height: 540,
    caption: 'Tensiones - Detail'
  },
  {
    src: image3,
    width: 960,
    height: 540,
    caption: 'Buscando luz'
  },
  {
    src: image4,
    width: 540,
    height: 960,
    caption: 'Cosas que quedaron sin decir'
  },
  {
    src: image5,
    width: 540,
    height: 960,
    caption: 'Sin salida'
  },
  {
    src: image6,
    width: 540,
    height: 960,
    caption: 'Cerca de la puerta'
  },{
    src: image7,
    width: 540,
    height: 960,
    caption: 'Cerca de la puerta los dos'
  },{
    src: image8,
    width: 540,
    height: 960,
    caption: 'Cerca de la puerta los dos - Detail'
  }
]
